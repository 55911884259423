export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [];

export const dictionary = {
		"/(site)": [~6,[2],[3]],
		"/(site)/about": [~16,[2],[3]],
		"/(site)/(withHeader)/author": [~7,[2,4],[3]],
		"/(site)/(withHeader)/author/[slug]": [~8,[2,4],[3]],
		"/(site)/(withHeader)/blog": [~9,[2,4],[3]],
		"/(site)/(withHeader)/blog/[slug]": [~10,[2,4],[3]],
		"/(site)/coaches": [~17,[2],[3]],
		"/email/notifications": [~19],
		"/email/notifications/[id]": [~20],
		"/email/welcome": [~21],
		"/email/welcome/[id]": [~22],
		"/lessons": [~24],
		"/lesson/[id]": [~23],
		"/(site)/players": [~18,[2],[3]],
		"/(site)/(withHeader)/privacy-policy": [~11,[2,4],[3]],
		"/proxytown/fb": [25],
		"/(site)/(withHeader)/terms-of-service": [~12,[2,4],[3]],
		"/unsubscribe": [26],
		"/(site)/(withHero)/[profile]": [~13,[2,5],[3]],
		"/(site)/(withHero)/[profile]/reviews": [~14,[2,5],[3]],
		"/(site)/(withHero)/[profile]/reviews/[page]": [~15,[2,5],[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';